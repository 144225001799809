@import '../../../styles/var';

.bestConvertingWrap {
    position: relative;
    z-index: 10;
    margin-top: 112px;
    @media(max-width: $md4+px) {
        margin-top: 135px;
    }
    @media(max-width: $md6+px) {
        margin-top: 90px;
    }
}

.bestConverting {
    position: relative;
    .palm {
        position: relative;
        z-index: 3;
        transform: translateX(-320px);
        width: 835px;
        @media(max-width: $md4+px) {
            width: 561px;
            transform: translateX(140px);
            display: flex;
            margin-left: auto;
        }
        @media(max-width: $md6+px) {
            width: 500px;
            transform: translateX(65px);
        }
    }
    .light {
        position: absolute;
        z-index: 1;
        bottom: 15px;
        right: -70px;
        width: 1177px;
        @media(max-width: $md3+px) {
            display: none;
        }   
    }
    .lightRed {
        position: absolute;
        z-index: 2;
        right: -100px;
        bottom: 160px;
        @media(max-width: $md4+px) {
            width: 230px;
            bottom: unset;
            top: -70px;
            right: 410px;
        }
        @media(max-width: 800px) {
            right: 300px;
        }
        @media(max-width: 600px) {
            right: 250px;
        }
        @media(max-width: $md6+px) {
            top: unset;
            bottom: -30px;
            right: 110px;
        }
    }
    .cardsWrap {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        .cards {
            display: grid;
            grid-template-columns: repeat(2, auto);
            grid-template-rows: repeat(2, auto);
            justify-content: center;
            gap: 37px 120px;
            padding-top: 137px;
            @media(max-width: $md3+px) {
                justify-content: space-between;
                gap: 37px 20px;
            }
            @media(max-width: $md4+px) {
                display: flex;
                justify-content: unset;
                gap: unset;
                padding-top: 28px;
            }
            .cardWrap {
                width: 450px;
                height: 266px;
                backdrop-filter: blur(15px);
                clip-path: polygon(0 0, 60% 0, 100% 20%, 100% 100%, 40% 100%, 0 80%);
                @media(max-width: $md4+px) {
                    width: 305px;
                    height: 238px;
                    position: absolute;
                }
                @media(max-width: $md6+px) {
                    width: 247px;
                    height: 204px;
                }
                &:nth-child(1) {
                    grid-column-start: 1;
                    grid-column-end: 1;
                    @media(max-width: $md4+px) {
                        right: 210px;
                        top: 28px;
                    }
                    @media(max-width: 800px) {
                        right: 100px;
                    }
                    @media(max-width: 600px) {
                        right: 50px;
                    }
                    @media(max-width: $md6+px) {
                        top: 51px;
                        right: unset;
                        left: 0;
                    }
                }
                &:nth-child(2) {
                    grid-row-start: 2;
                    grid-row-end: 2;
                    grid-column-start: 2;
                    grid-column-end: 2;
                    @media(max-width: $md4+px) {
                        bottom: 85px;
                        left: 0;
                    }
                    @media(max-width: $md6+px) {
                        left: unset;
                        right: 0;
                        bottom: 60px;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border: 4px solid rgba(205, 2, 108, 0.97);
                }
                .card {
                    position: relative;
                    z-index: 10;
                    width: 100%;
                    height: 100%;
                    padding: 46px 33px;
                    @media(max-width: $md4+px) {
                        padding: 40px 30px;
                    }
                    @media(max-width: $md6+px) {
                        padding: 35px 25px;
                    }
                    // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
                    &Title {

                    }
                    &List {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        margin-top: 20px;
                        padding-left: 30px;
                        @media(max-width: $md6+px) {
                            margin-top: 15px;
                            padding-left: 25px;
                        }
                        li {
                            list-style-type: disc;
                            list-style-position: outside;
                        }
                    }
                }
            }
        }
    }
}