@import "./_var.scss";

body,
html {
    background-color: #000;
    color: #fff;
    &.lock {
        overflow: hidden;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background-color: #000;
    }
    ::-webkit-scrollbar-thumb {
        background: #F83CC3;
    }

    
	cursor: url('https://s.electerious.com/images/codepen/cursor.svg') 3 3, auto!important;
	-webkit-font-smoothing: antialiased!important;
	-moz-osx-font-smoothing: grayscale!important;
}

.font-80-caveat {
    color: #F83CC3;
    font-family: $caveat;
    font-size: 80px;
    font-weight: 400;
    line-height: 140%;
    @media(max-width: $md4+px) {
        font-size: 65px!important;
    }
    @media(max-width: $md6+px) {
        font-size: 35px!important;
    }
}

.font-75 {
    color: #FFF;
    font-family: $serif;
    font-size: 75px;
    font-weight: 500;
    line-height: 140%;
    @media(max-width: $md4+px) {
        font-size: 60px!important;
    }
    @media(max-width: $md6+px) {
        font-size: 31px!important;
    }
}

.font-45 {
    color: #F83CC3;
    font-family: $montserrat;
    font-size: 45px;
    font-weight: 900;
    line-height: 140%;
    @media(max-width: $md4+px) {
        font-size: 40px;
    }
    @media(max-width: $md6+px) {
        font-size: 25px;
    }
}

.font-25 {
    position: relative;
    color: #FFF;
    font-family: $montserrat;
    font-size: 25px;
    font-weight: 700;
    line-height: 100%;
    display: inline-flex;
    padding: 0 12px;
    @media(max-width: $md4+px) {
        font-size: 20px;
    }
    @media(max-width: $md6+px) {
        font-size: 18px;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: #F83CC3;
    }
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: #F83CC3;
    }
}

.font-20 {
    color: #FFF;
    font-family: $sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%;
    @media(max-width: $md4+px) {
        font-size: 18px;
    }
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-18 {
    color: #FFF;
    font-family: $sans;
    font-size: 18px;
    font-weight: 300;
    line-height: 150%;
    @media(max-width: $md4+px) {
        font-size: 16px!important;
    }
    @media(max-width: $md6+px) {
        font-size: 14px!important;
    }
}

.font-18-mont {
    color: #F83CC3;
    font-family: $montserrat;
    font-size: 18px;
    font-weight: 900;
    line-height: 140%;
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-17 {
    color: #FFF;
    font-family: $sans;
    font-size: 17px;
    font-weight: 300;
    line-height: 150%;
    @media(max-width: $md4+px) {
        font-size: 16px;
    }
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.mt {

    @media (max-width: $md1+px) {
        
    }
    @media (max-width: $md6+px) {

    }
}

.pt {
    
    @media (max-width: $md1+px) {

    }
    @media (max-width: $md6+px) {

    }
}

.ovf-hidden {
    overflow: hidden;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Dropdown-control {
    font-family: $sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: transparent;
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding-right: 30px;
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }
}
.Dropdown-menu {
    font-family: $sans;
    font-style: normal;
    font-size: 14px;
}
.Dropdown-arrow {
    border-color: #fff transparent transparent;
}
.is-open .Dropdown-arrow {
    border-color: transparent transparent #fff;
}

.site-content {
    position: relative;
    z-index: 3;
}

.cursor {
    @media(max-width: $md3+px) {
        display: none!important;
    }

	display: none;
	position: fixed;
    z-index: 9999999;
	top: 0;
	left: 0;
	width: var(--width);
	height: var(--height);
	transform:
		translate(
			calc(var(--x) - var(--width) / 2),
			calc(var(--y) - var(--height) / 2)
		);
	transition-duration: .1s;
	transition-timing-function: cubic-bezier(.25, .25, .42, 1);
	transition-property: width, height, transform;
	pointer-events: none;
	will-change: transform;

	@media (pointer: fine) {
		display: block;
	}

	&::after {
		content: '';
		position: absolute;
        z-index: 100;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: var(--radius);
		border: 2px solid #fff;
		opacity: var(--scale);
		transform: scale(var(--scale));
		transition:
			.3s cubic-bezier(.25, .25, .42, 1) opacity,
			.3s cubic-bezier(.25, .25, .42, 1) transform,
			.1s cubic-bezier(.25, .25, .42, 1) border-radius;
	}
}

body:not(body:hover) .cursor::after {
	opacity: 0;
	transform: scale(0);
}
