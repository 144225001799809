@import '../../../styles/var';

.valuesWrap {
    position: relative;
    margin-top: 250px;
    @media(max-width: $md3+px) {
        margin-top: 200px;
    }
    @media(max-width: $md4+px) {
        margin-top: 160px;
    }
    @media(max-width: $md6+px) {
        margin-top: 90px;
    }
}

.values {
    position: relative;
    .light {
        position: absolute;
        z-index: 2;
        top: -50px;
        left: -75px;
        width: 747px;
        @media(max-width: $md3+px) {
            left: unset;
            right: 110px;
            transform: scaleX(-1)
        }
        @media(max-width: $md4+px) {
            right: -10px;
            top: -60px;
        }
        @media(max-width: 700px) {
            width: 470px;
            right: unset;
            left: -30px;
            top: -20px;
        }
        @media(max-width: $md6+px) {
            width: 367px;
            left: -45px;
        }
    }
    .lightRed {
        position: absolute;
        z-index: 1;
        width: 520px;
        top: -200px;
        left: -120px;
        @media(max-width: $md3+px) {
            left: unset;
            right: 120px;
            top: -180px;
        }
        @media(max-width: $md4+px) {
            right: 50px;
        }
        @media(max-width: 700px) {
            width: 400px;
            top: -130px;
            left: -90px;
            right: unset;
        }
        @media(max-width: $md6+px) {
            width: 296px;
            top: -100px;
        }
    }
    .title {
        position: absolute;
        z-index: 10;
        top: -10px;
        left: -20px;
        @media(max-width: $md3+px) {
            position: relative;
            top: unset;
            left: unset;
            display: flex;
            justify-content: flex-end;
        }
        @media(max-width: 700px) {
            justify-content: flex-start;
        }
        &En {
            width: 402px;
            @media(max-width: $md3+px) {
                margin-right: 200px;
            } 
            @media(max-width: $md4+px) {
                margin-right: 100px;
                width: 350px;
            }
            @media(max-width: 700px) {
                width: 280px;
            }
            @media(max-width: $md6+px) {
                width: 190px;
            }
        }
        &Ua {
            width: 546px;
            @media(max-width: $md3+px) {
                margin-right: 100px;
            }
            @media(max-width: $md4+px) {
                margin-right: 50px;
                width: 420px;
            }
            @media(max-width: 700px) {
                width: 315px;
            }
            @media(max-width: $md6+px) {
                width: 250px;
            }
        }
    }
    .cards {
        position: relative;
        z-index: 10;
        @media(max-width: $md3+px) {
            display: flex;
            flex-direction: column;
            gap: 35px;
            margin-top: 40px;
            margin-left: auto;
            width: 400px;
        }
        @media(max-width: $md4+px) {
            width: 270px;
            margin-top: 30px;
        }
        @media(max-width: $md6+px) {
            gap: 25px;
            width: 190px;
            margin-top: 10px;
        }
        .card {
            position: absolute;
            @media(max-width: $md3+px) {
                position: relative;
                left: unset!important;
                right: unset!important;
                top: unset!important;
            }
            &:nth-child(1) {
                right: 50px;
                top: 45px;
            }
            &:nth-child(2) {
                top: 300px;
                right: 0;    
            }
            &:nth-child(3) {
                top: 300px;
                left: 0;
            }
            &Title {
                color: #F83CC3;
                font-family: $montserrat;
                font-size: 25px;
                font-weight: 900;
                line-height: 140%;
                @media(max-width: $md4+px) {
                    font-size: 18px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
            }
            &Text {
                color: #FFF;
                font-family: $sans;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                margin-top: 8px;
                max-width: 240px;
                @media(max-width: $md4+px) {
                    font-size: 16px;
                    max-width: 200px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    max-width: 180px;
                }
            }
        }
    }
    .house {
        position: relative;
        z-index: 3;
        display: flex;
        margin: 0 auto;
        width: 659px;
        @media(max-width: $md3+px) {
            position: absolute;
            bottom: -30px;
            margin: unset;
            right: 500px;
        }
        @media(max-width: $md4+px) {
            width: 580px;
            right: 400px;
        }
        @media(max-width: 700px) {
            width: 430px;
            right: 330px;
        }
        @media(max-width: $md6+px) {
            width: 320px;
            right: 210px;
            bottom: -10px;
        }
        img {
            width: 100%;
            @media(max-width: $md3+px) {
                transform: scaleX(-1)
            }
        }
    }
}