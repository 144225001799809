@import '../../../styles/var';

.blockWrap {
    position: relative;
    .palm_geo {
        position: absolute;
        z-index: 1;
        right: 0;
        top: -350px;
        @media(max-width: $md4+px) {
            display: none;
        }
    }
}

.block {
    position: relative;
    width: 100%;
    max-width: 621px;
    @media(max-width: $md3+px) {
        margin-left: auto!important;
        margin-right: auto!important;
    }
    &_01 {
        margin-top: 452px;
        @media(max-width: $md3+px) {
            margin-top: 427px;
        }
        @media(max-width: 600px) {
            margin-top: 330px;
        }
        @media(max-width: $md6+px) {
            margin-top: 238px;
        }
    }
    &_02 {
        margin: 155px 0 221px auto;
        @media(max-width: $md3+px) {
            margin: 336px auto 415px auto;
        }
        @media(max-width: 600px) {
            margin: 250px auto 320px auto;
        }
        @media(max-width: $md6+px) {
            margin: 185px auto 234px auto;
        }
    }
    &_03 {
        .light {
            position: absolute;
            z-index: 1;
            bottom: -90px;
            left: 30px;
            width: 903px;
            @media(max-width: $md3+px) {
                display: none;
            }
        }
    }
    .content {
        .num {

        }
        .title {
            margin-top: 8px;
            @media(max-width: $md4+px) {
                margin: 10px 0;
            }
        }
        .text {
            max-width: 300px;
        }
        .line {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 3px;
            background: rgba(255, 255, 255, 0.50);
            border-radius: 5px;
            margin: 17px 0 50px 0;
            @media(max-width: $md4+px) {
                margin: 15px 0 30px 0;
            }
        }
        .button {
            border-radius: 60px;
            background: #F83CC3;
            font-weight: 400;
            padding: 12px 72px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            transition: all .3s;
            @media(max-width: $md4+px) {
                padding: 12px 60px;
            }
            &:hover {
                box-shadow: inset 0 0 30px 0 #b8208d, 0 0 30px 0 #c22696;
                img {
                    transform: translateX(10px) translateY(-5px) scale(0.7);
                }
                .buttonBodyText {
                    transform: translateX(10px);
                }
            }
            &Body {
                position: relative;
                .buttonBodyText {
                    transition: all .3s;
                }
                img {
                    position: absolute;
                    left: -30px;
                    width: 24px;
                    top: calc(50% - 12px);
                    transition: all .3s;
                }
            }
        }
        .num, .title, .text, .button {
            position: relative;
            z-index: 10;
        }
    }
    .image {
        position: absolute;
        z-index: 4;
        height: 600px;
        @media(max-width: $md3+px) {
            height: 500px;
        }
        @media(max-width: 600px) {
            height: 400px;
        }
        @media(max-width: $md6+px) {
            height: 300px;
        }
        &_02 {
            right: 0;
            bottom: 64px;
            @media(max-width: $md4+px) {
                bottom: 40px;
            }
            @media(max-width: $md6+px) {
                bottom: 60px;
            }
        }
        &_03 {
            right: 0;
            bottom: 103px;
            @media(max-width: $md4+px) {
                bottom: 80px;
            }
            @media(max-width: $md6+px) {
                bottom: 77px;
            }
        }
    }
    .palm {
        position: absolute;
        z-index: 3;
        width: 417px;
        bottom: 209px;
        right: -10px;
        @media(max-width: $md3+px) {
            width: 347px;
            right: 20px;
            bottom: 180px;
        }
        @media(max-width: $md4+px) {
            bottom: 160px;
        }
        @media(max-width: 600px) {
            width: 280px;
            bottom: 140px;
        }
        @media(max-width: $md6+px) {
            width: 200px;
            bottom: 120px;
            right: 15px;
        }
    }
    .cases {
        position: absolute;
        z-index: 4;
        width: 359px;
        right: -56px;
        bottom: 37px;
        @media(max-width: $md3+px) {
            width: 300px;
            right: -20px;
        }
        @media(max-width: $md4+px) {
            bottom: 10px;
        }
        @media(max-width: 600px) {
            width: 250px;
        }
        @media(max-width: $md6+px) {
            width: 160px;
            bottom: 40px;
            right: 0;
        }
    }
}