$sans: "Open Sans";
$montserrat: "Montserrat";
$serif: "Noto Serif";
$caveat: "Caveat";

$minwidth: 375px;
$mw: 1620;
$md1: $mw + 30;
$md2: 1420;
$md3: 1220;
$md4: 992;
$md5: 768;
$md6: 480;
