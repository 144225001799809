@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s;
    background-color: rgba(0, 0, 0, 0.9);
    &.hideBg {
        background-color: rgba(0, 0, 0, 0);
    }
    &.lightBg {
        .headerRowLinks {
            a {
                color: #fff;
            }
            .linkActive {
                color: #7a51af;
            }
        }
        :global(.Dropdown-control) {
            color: #fff !important;
        }
        :global(.Dropdown-arrow) {
            border-color: #fff transparent transparent !important;
        }
        :global(.is-open .Dropdown-arrow) {
            border-color: transparent transparent #fff !important;
        }
    }
    @media (max-width: $md3+px) {
        height: 84px;
    }
    @media (max-width: $md6+px) {
        height: 68px;
    }
    &::before {
        content: "";
        background: #ffffff;
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        transition: all 0.3s;
        z-index: -1;
    }
    &Active {
        height: 100%;
        &::before {
            opacity: 1;
            height: 100%;
        }
        .langs {
            display: none;
        }
        path {
            fill: white;
        }
    }
    .mobileMenu {
        @media (min-width: 1221px) {
            display: none;
        }
        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;
        .mobileContainer {
            padding: 0 40px;
            width: 100%;
            @media (max-width: 600px) {
                padding: 0 20px;
            }
        }
        .aLink {
            display: block;
            text-align: center;
            color: #2a2b2b;
            font-family: $sans;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            width: 90%;
            margin: 0 auto 14px auto;
            @media (max-width: $md6+px) {
                text-align: left;
                width: 80%;
                margin-left: 0;
            }
        }
        .btn {
            justify-content: center;
            display: flex;
            margin-top: 40px;
            @media (max-width: 600px) {
                justify-content: flex-start;
            }
            a {
                @media (min-width: 1221px) {
                    display: none;
                }
                display: block;
                padding: 5px 20px;
                background: #293A4F;
                border: 1px solid #293A4F;
                border-radius: 30px;
                font-family: $sans;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 150%;
                color: #fff;
                cursor: pointer;
                transition: all 0.3s;
                @media (max-width: $md1+px) {
                    font-size: 16px;
                }
                @media (max-width: $md2+px) {
                    font-size: 14px;
                }
                &:hover {
                    color: #293A4F;
                    background: transparent;
                }
            }
        }
        &Active {
            display: flex;
            transition: transform 0.5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }
    }
    .headerRow {
        display: grid;
        grid-template-columns: 57px 1fr auto;
        gap: 60px;
        align-items: center;
        padding: 18px 0;
        @media (max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
        }
        @media (max-width: $md6+px) {
            padding: 12px 0;
        }
        &Logo {
            @media (max-width: $md3+px) {
                width: 50px;
            }
            @media(max-width: $md6+px) {
                width: 46px;
            }
            a {
                display: block;
                height: 100%;
                width: 100%;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        &Links {
            @media (max-width: $md3+px) {
                display: none;
            }
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 50px;
            font-family: $sans;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            @media (max-width: $md2+px) {
                font-size: 14px;
                line-height: 21px;
                gap: 40px;
            }
            .linkActive {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -2px;
                    width: 100%;
                    height: 1px;
                    background: #2a2b2b;
                }
            }
            a {
                color: #2a2b2b;
                text-decoration: none;
            }
        }
        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 35px;
            a {
                display: flex;
                align-items: center;
                svg {
                    @media(max-width: $md6+px) {
                        transform: scale(0.8);
                    }
                    path {
                        transition: all .3s;
                    }
                }
                &:hover {
                    svg path {
                        fill: #F83CC3;
                    }
                }
            }
            .login {
                @media (max-width: $md3+px) {
                    display: none;
                }
                padding: 5px 20px;
                background: #293A4F;
                border: 1px solid #293A4F;
                font-family: $sans;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                color: #fff;
                cursor: pointer;
                transition: all 0.3s;
                border-radius: 30px;
                @media (max-width: $md2+px) {
                    font-size: 14px;
                }
                &:hover {
                    color: #293A4F;
                    background: transparent;
                }
            }
            .langs {
                .lang {
                    position: relative;
                    cursor: pointer;
                    font-size: 16px;
                    line-height: 150%;
                    transition: all .3s;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 100%;
                        height: 2px;
                        background: #F83CC3;
                        opacity: 0;
                    }
                    &:hover {
                        &::before {
                            opacity: 1;
                        }
                        // color: #F83CC3;
                    }
                }
            }
            .burger {
                @media (min-width: 1221px) {
                    display: none;
                }
                cursor: pointer;
                display: flex;
                position: relative;
                width: 24px;
                height: 20px;
                transition: all 0.3s;
                span {
                    transition: all 0.3s;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    height: 2px;
                    background: #2a2b2b;
                    &:nth-child(1) {
                        top: 0;
                    }
                    &:nth-child(2) {
                        top: calc(50% - 1px);
                    }
                    &:nth-child(3) {
                        bottom: 0;
                    }
                }
                &Active {
                    span {
                        &:nth-child(1) {
                            transform: rotate(405deg) translateY(13px);
                            left: 7px;
                        }
                        &:nth-child(2) {
                            opacity: 0;
                        }
                        &:nth-child(3) {
                            transform: rotate(-405deg) translateY(-13px);
                            left: 7px;
                        }
                    }
                }
            }
        }
    }
}
