.container {
    max-width: 1220px;
    margin: 0 auto;
    @media (max-width: $md2+px) {
        max-width: 1200px;
    }
    @media (max-width: $md3+px) {
        max-width: none;
        padding: 0 40px;
    }
    @media (max-width: 600px) {
        padding: 0 18px;
    }
}
