@import '../../../styles/var';

.bannerWrap {
    position: relative;
    background: linear-gradient(0deg, rgba(248, 60, 195, 0.67) 0%, rgba(248, 60, 195, 0.50) 22.43%, #06000B 77.08%, #000 96.00%);
    .images {
        position: absolute;
        overflow: hidden;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        &::before {
            z-index: 2;
            content: '';
            position: absolute;
            bottom: 0;
            left: calc(50% - 2045px / 2);
            width: 2045px;
            height: 27px;
            background-image: url('../../../assets/img/HomePage/Banner/ground.png');
            background-position: bottom center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .buildWrap, .girlWrap, .carWrap, .palmWrap {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        img {
            width: 100%;
        }
    }
    .buildWrap {
        z-index: 1;
        &_01 {
            width: 642px;
            left: -1200px;
            transform: translateX(0px) translateY(30px) scale(0.93);
            @media (max-height: 900px) {
                width: 600px;
                left: -1150px;
            }
            @media(max-height: 800px) {
                width: 520px;
                left: -1080px;
            }
            @media(max-height: 690px) {
                width: 460px;
                left: -990px;
            }
            @media(max-width: $md3+px) {
                width: 508px!important;
                left: -520px!important;
                transform: none!important;
            }
            @media(max-width: $md6+px) {
                width: 232px!important;
                left: -260px!important;
            }
        }
        &_02 {
            width: 250px;
            right: -1270px;
            transform: translateX(0px) translateY(40px) scale(0.93);
            @media (max-height: 900px) {
                width: 220px;
                right: -1210px;
            }
            @media(max-height: 800px) {
                width: 190px;
                right: -1130px;
            }
            @media(max-height: 690px) {
                width: 160px;
                right: -960px;
            }
            @media(max-width: $md3+px) {
                width: 214px!important;
                right: -550px!important;
                transform: none!important;
            }
            @media(max-width: $md6+px) {
                width: 133px!important;
                right: -290px!important;
            }
        }
    }
    .girlWrap {
        z-index: 2;
        &_01 {
            width: 208px;
            left: -700px;
            transform: translateX(20px) translateY(12px) scale(0.96);
            @media (max-height: 900px) {
                width: 180px;
                left: -680px;
            }
            @media(max-height: 800px) {
                width: 160px;
                left: -670px;
            }
            @media(max-height: 690px) {
                width: 140px;
                left: -620px;
            }
            @media(max-width: $md3+px) {
                width: 158px!important;
                left: -230px!important;
            }
            @media(max-width: $md6+px) {
                width: 129px!important;
                left: -160px!important;
            }
        }
        &_02 {
            width: 204px;
            right: -870px;
            transform: translateX(-20px) translateY(12px) scale(0.96);
            @media (max-height: 900px) {
                width: 180px;
            }
            @media(max-height: 800px) {
                width: 160px;
                right: -840px;
            }
            @media(max-height: 690px) {
                width: 140px;
                right: -700px;
            }
            @media(max-width: $md3+px) {
                width: 158px!important;
                right: -260px!important;
                transform: none!important;
            }
            @media(max-width: $md6+px) {
                width: 129px!important;
                right: -140px!important;
            }
        }
    }
    .carWrap {
        z-index: 3;
        @media (max-height: 900px) {
            bottom: -6px;
        }
        @media(max-width: $md3+px) {
            display: none!important;
        }
        &_01 {
            width: 369px;
            left: -1090px;
            transform: translateX(-400px);
            @media (max-height: 900px) {
                transform: translateX(-400px) scale(0.9);
                left: -840px;
            }
            .wheelWrap_01 {
                left: 53px;
                @media(max-width: $md3+px) {
                    left: 30px;
                }
            }
            .wheelWrap_02 {
                right: 75px;
            }
        }
        &_02 {
            width: 371px;
            right: -400px;
            transform: translateX(250px);
            @media (max-height: 900px) {
                transform: translateX(250px) scale(0.9);
                right: -440px;
            }
            .wheelWrap_01 {
                left: 76px;
            }
            .wheelWrap_02 {
                right: 53px;
            }
        }
        img {
            position: relative;
            z-index: 2;
        }
        .wheelWrap {
            position: absolute;
            z-index: 3;
            bottom: 4px;
        }
    }
    .palmWrap {
        z-index: 4;
        &_01 {
            width: 189px;
            left: 0;
            right: unset;
            @media (max-height: 900px) {
                width: 170px;
            }
            @media(max-height: 800px) {
                width: 140px;
            }
            @media(max-height: 690px) {
                width: 120px;
            }
            @media(max-width: $md3+px) {
                width: 126px!important;
            }
            @media(max-width: $md6+px) {
                width: 98px!important;
            }
        }
        &_02 {
            width: 191px;
            right: -1150px;
            @media (max-height: 900px) {
                width: 170px;
                right: -1120px;
            }
            @media(max-height: 800px) {
                width: 140px;
                right: -1050px;
            }
            @media(max-height: 690px) {
                width: 120px;
            }
            @media(max-width: $md3+px) {
                width: 136px!important;
                left: unset!important;
                right: -30px!important;
            }
            @media(max-width: $md6+px) {
                width: 100px!important;
            }
        }
    }
    .car_mob {
        display: none;
        @media(max-width: $md3+px) {
            display: flex;
            width: 222px;
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        @media(max-width: $md6+px) {
            width: 140px;
        }
        &_01 {
            left: -500px;
            @media(max-width: $md6+px) {
                left: -210px;
            }
        }
        &_02 {
            right: -420px;
            @media(max-width: $md6+px) {
                right: -210px;
            }
        }
    }
}

.banner {
    position: relative;
    height: 100vh;
    padding-top: 140px;
    min-height: 630px;
    max-height: 1300px;
    @media(max-height: 900px) {
        padding-top: 120px;
    }
    @media(max-height: 830px) {
        padding-top: 100px;
    }
    @media(max-width: $md3+px) {
        min-height: unset!important;
        max-height: unset!important;
        height: auto!important;
        padding-bottom: 538px;
        padding-top: 140px!important;
    }
    @media(max-width: $md4+px) {
        padding-top: 120px!important;
    }
    @media(max-width: $md6+px) {
        padding-top: 100px!important;
        padding-bottom: 446px;
    }
    .content {
        position: relative;
        z-index: 10;
        text-align: center;
        .title {
            display: flex;
            flex-direction: column;
            &Text_01 {
                @media(max-height: 730px) {
                    font-size: 65px;
                }
            }
            &Text_02 {
                position: relative;
                overflow: hidden;
                margin-top: -30px;
                @media(max-height: 730px) {
                    font-size: 60px;
                }
                @media(max-width: $md4+px) {
                    margin-top: -20px;
                }
                @media(max-width: $md6+px) {
                    margin-top: 0;
                }
            }
        }
        .text {
            margin: 20px auto 50px auto;
            max-width: 650px;
            @media(max-height: 730px) {
                font-size: 16px;
                max-width: 550px;
            }
            @media(max-width: $md4+px) {
                max-width: 550px!important;
            }
            @media(max-width: $md6+px) {
                max-width: 339px!important;
            }
        }
        .buttons {
            display: flex;
            justify-content: center;
            gap: 30px;
        }
    }
}
