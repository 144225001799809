@import '../../../styles/var';

.advantages {
    position: relative;
    z-index: 10;
    margin-top: 250px;
    @media(max-width: $md4+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 90px;
    }
    .title, .titleMob {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
        text-align: center;
        display: flex;
        margin: 0 auto;
        @media(max-width: 700px) {
            text-align: left;
            margin-bottom: 20px;
        }
        &En {
            width: 662px;
            @media(max-width: $md4+px) {
                width: 550px;
            }
            @media(max-width: 700px) {
                width: 360px;
            }
            @media(max-width: $md6+px) {
                width: 300px;
            }
        }
        &Ua {
            width: 527px;
            @media(max-width: $md4+px) {
                width: 430px;
            }
            @media(max-width: 700px) {
                width: 300px;
            }
            @media(max-width: $md6+px) {
                width: 240px;
            }
        }
    }
    .title {
        @media(max-width: 700px) {
            display: none;
        }
    }
    .titleMob {
        display: none;
        @media(max-width: 700px) {
            display: block;
        }
    }
    .cards {
        position: relative;
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(3, auto);
        gap: 20px 0;
        @media(max-width: $md4+px) {
            gap: 20px 70px;
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: 800px) {
            gap: 20px 40px;
        }
        @media(max-width: 700px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
            gap: 50px;
        }
        .cardWrap {
            position: relative;
            backdrop-filter: blur(2px);
            background: linear-gradient(180deg, rgba(78, 17, 49, 0.60) 0%, rgba(248, 60, 195, 0.00) 100%);
            &::before {
                content: '';
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: 3px solid rgba(205, 2, 108, 0.97);
            }
            .card {
                position: relative;
                z-index: 8;
                padding: 35px;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
                height: 187px;
                width: 444px;
                @media(max-width: $md4+px) {
                    width: 100%;
                    height: 100%;
                    padding: 25px 13px;
                }
                @media(max-width: 700px) {
                    padding: 25px 20px;
                }
                &Ua {
                    height: 210px;
                    @media(max-width: $md4+px) {
                        height: 100%;
                    }
                }
                &Title {
                    position: relative;
                    z-index: 10;
                }
                &Text {
                    margin-top: 15px;
                }
            }
        }
    }
}