@import "../../../styles/var";

.footerWrap {
    position: relative;
    z-index: 5;
    background: #000;
    &::before {
        content: '';
        position: absolute;
        z-index: 2;
        background-image: url('../../../assets/img/Footer/bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        width: 1945px;
        height: 737px;
        bottom: 170px;
        left: calc(50% - 1945px / 2);
        @media(min-width: 1940px) {
            height: 1000px;
            width: 100%;
            background-size: contain;
            left: 0;
        }
        @media(max-width: $md4+px) {
            width: calc(1945px * 0.9);
            height: calc(737px * 0.9);
            right: unset;
            left: -300px;
            bottom: 290px;
        }
        @media(max-width: $md5+px) {
            width: 1945px;
            height: 737px;
            bottom: 380px;
            left: calc(50% - 850px);
        }
    }
    .car {
        position: absolute;
        z-index: 3;
        bottom: 150px;
        left: 0;
        right: -510px;
        margin: 0 auto;
        width: 545px;
        @media(max-width: $md4+px) {
            width: 265px;
            right: unset;
            left: 350px;
            bottom: 280px;
        }
        @media(max-width: $md5+px) {
            width: 240px;
            left: 50px;
            right: 0;
            bottom: 370px;
        }
    }
    .light {
        position: absolute;
        z-index: 2;
        width: 563px;
        bottom: 25px;
        left: 0;
        right: -400px;
        margin: 0 auto;
        @media(max-width: $md4+px) {
            right: -150px;
            width: 450px;
            bottom: 140px;
        }
        @media(max-width: $md5+px) {
            width: 350px;
            bottom: 280px;
            right: -120px;
        }
    }
}

.footer {
    position: relative;
    z-index: 10;
    margin-top: 375px;
    padding-top: 40px;
    padding-bottom: 70px;
    color: rgba(255, 255, 255, 0.70);
    font-family: $montserrat;
    font-size: 17px;
    font-weight: 300;
    line-height: 150%;
    display: grid;
    grid-template-columns: 57px 1fr auto;
    gap: 60px;
    @media(max-width: $md3+px) {
        font-size: 16px;
    }
    @media(max-width: $md4+px) {
        margin-top: 349px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    @media(max-width: $md6+px) {
        margin-top: 290px;
    }
    .logo {
        width: 57px;
        @media(max-width: $md3+px) {
            margin-bottom: 15px;
        }
        &Img {
            width: 100%;
        }
    }
    .col {
        .address {

        }
        .docs {
            display: flex;
            justify-content: flex-end;
            gap: 25px;
            @media(max-width: $md4+px) {
                justify-content: flex-start;
            }
            @media(max-width: $md5+px) {
                flex-direction: column;
                gap: 15px;
            }
            a {
                color: rgba(255, 255, 255, 0.70);
                padding: 0 5px;
                transform: translateX(5px);
                @media(max-width: $md3+px) {
                    padding: 0;
                    transform: none;
                }
            }
        }
        .rights {
            margin-top: 15px;
            text-align: right;
            @media(max-width: $md4+px) {
                text-align: left;
            }
        }
    }
}


.smokeWrap {
    position: absolute;
    z-index: 200;
    left: 0;
    right: -150px;
    margin: 0 auto;
    bottom: 220px;
    width: 200px;
    @media(max-width: $md4+px) {
        width: 120px;
        bottom: 320px;
        right: unset;
        left: 350px;
    }
    @media(max-width: $md5+px) {
        left: -90px;
        right: 0;
        bottom: 400px;
    }
}
  
.smoke,
.smoke2,
.smoke3 {
    filter: blur(5px);
    transform-origin: 50% 50%;
}
  
.smoke {
    animation: smoke1 3s linear infinite;
    animation-delay: 0.5s;
    @media(max-width: $md4+px) {
        width: 120px;    
    }
}
  
.smoke2 {
    animation: smoke2 3s linear infinite;
    animation-delay: 1.5s;
    @media(max-width: $md4+px) {
        width: 120px;    
    }
}
  
.smoke3 {
    width: 200px;
    animation: smoke3 4s linear infinite;
    animation-delay: 2.5s;
    @media(max-width: $md4+px) {
        width: 120px;    
    }
}
  
@keyframes smoke1 {
    0% {
        filter: blur(0px);
        transform: translateY(0px) scale(-1, 1);
        opacity: 0;
    }
    25% {
        filter: blur(3px);
        transform: translateY(-10px) scale(-1, 1.05);
        opacity: 0.5;
    }
    50% {
        filter: blur(5px);
        transform: translateY(-20px) scale(-1, 1.1);
        opacity: 1;
    }
    75% {
        filter: blur(5px);
        transform: translateY(-30px) scale(-1, 1.15);
        opacity: 0.5;
    }
    100% {
        filter: blur(7px);
        transform: translateY(-40px) scale(-1, 1.2);
        opacity: 0;
    }
}
  
@keyframes smoke2 {
    0% {
        filter: blur(0px);
        transform: translateY(0px) scale(1);
        opacity: 0;
    }
    25% {
        filter: blur(3px);
        transform: translateY(-10px) scale(1.05);
        opacity: 0.5;
    }
    50% {
        filter: blur(5px);
        transform: translateY(-20px) scale(1.1);
        opacity: 1;
    }
    75% {
        filter: blur(5px);
        transform: translateY(-30px) scale(1.15);
        opacity: 0.5;
    }
    100% {
        filter: blur(7px);
        transform: translateY(-40px) scale(1.2);
        opacity: 0;
    }
}
  
@keyframes smoke3 {
    0% {
        filter: blur(0px);
        transform: translateY(0px) scale(1);
        opacity: 0;
    }
    25% {
        filter: blur(3px);
        transform: translateY(-20px) scale(1.05);
        opacity: 0.5;
    }
    50% {
        filter: blur(5px);
        transform: translateY(-40px) scale(1.1);
        opacity: 1;
    }
    75% {
        filter: blur(5px);
        transform: translateY(-60px) scale(1.15);
        opacity: 0.5;
    }
    100% {
        filter: blur(7px);
        transform: translateY(-80px) scale(1.2);
        opacity: 0;
    }
}